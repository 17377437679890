<template>
  <div>
    <div class="h-[120px] w-[140px] overflow-hidden">
      <animated-logo />

      <button
        v-if="showStickers"
        class="absolute right-[3px] top-[20px] h-16 w-16 translate-x-14 cursor-pointer opacity-0 transition-all dark:translate-x-0 dark:opacity-100"
        @click="
          () => {
            showModal = true
            showModalImage = '/images/darth-vader-sticker.png'
          }
        "
      >
        <fr-image src="/images/darth-vader-sticker.png" />
      </button>

      <button
        v-if="showStickers"
        class="absolute left-[3px] top-[20px] h-16 w-16 translate-x-0 cursor-pointer opacity-100 transition-all dark:translate-x-14 dark:opacity-0"
        @click="
          () => {
            showModal = true
            showModalImage = '/images/yoda-sticker.png'
          }
        "
      >
        <fr-image src="/images/yoda-sticker.png" />
      </button>
    </div>

    <q-dialog v-model="showModal">
      <div class="flex flex-col items-center justify-center gap-3">
        <fr-image :src="showModalImage" />
        <h3 class="text-4xl">May the 4th be with you</h3>
      </div>
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
const showStickers = ref(false)
const showModal = ref(false)
const showModalImage = ref('')
</script>
